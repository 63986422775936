import axios from "axios";
import { getSearchLength } from "./helper";

export default class SearchForm {
  constructor(container) {
    this.container = container;
    this.form = this.container.querySelector("form");
    this.serchTerm = null;
    this.timeout = null;
    this.openBtn = document.querySelector(".open-seach-form-btn");
    this.closeBtn = document.querySelector(".close-search-form-popup");
    this.formPopup = document.querySelector(".search-form-popup");
    this.resultsContainer = this.formPopup.querySelector(
      ".search-results-wrapper .wrapper"
    );
    this.pageContainer = document.getElementById("page");
    this.loader = this.formPopup.querySelector(".s-loader");
    this.init();
  }

  init() {
    this.openBtn.addEventListener("click", e => {
      e.preventDefault();
      this.formPopup.classList.add("open");
      this.pageContainer.classList.add("darken");
      setTimeout(() => {
        document.querySelector(".s-input").focus();
      }, 100);
    });

    this.closeBtn.addEventListener("click", e => {
      e.preventDefault();
      this.formPopup.classList.remove("open");
      this.pageContainer.classList.remove("darken");
    });

    this.form.addEventListener("input", e => {
      this.searchTerm = e.target.value;
      clearTimeout(this.timeout);
      if (this.searchTerm.length > 2) {
        this.resultsContainer.innerHTML = "";
        this.showLoader();
        this.timeout = setTimeout(() => {
          this.getData(
            `${jsData.siteUrl}/wp-json/kreacja/v1/search?key=${this.searchTerm}`
          );
        }, 1000);
      }
    });
  }

  async getData(url) {
    const options = {
      method: "GET",
      url: url
    };

    axios
      .request(options)
      .then(response => {
        const length = getSearchLength(response.data);
        if (length > 0) {
          this.buildList(response.data);
        } else {
          this.nothingFound();
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  buildList(data) {
    const typeArrays = Object.values(data);
    typeArrays.map(type => {
      if (type.data.length > 0) {
        const container = document.createElement("div");
        const heading = document.createElement("h4");
        container.classList.add("search-results");
        heading.innerText = type.heading;
        container.appendChild(heading);

        type.data.map(item => {
          const itemEl = document.createElement("a");

          const { permalink, price, name, images } = item;

          itemEl.innerHTML +=
            images !== undefined ? `<img src='${images.thumbnail}' />` : ``;

          itemEl.innerHTML += `<span class='name'>${name}</span>`;

          itemEl.innerHTML +=
            price !== undefined ? `<span class='price'>${price}</span>` : ``;
          itemEl.href = permalink;
          container.appendChild(itemEl);
        });

        this.resultsContainer.appendChild(container);
      }
    });

    this.hideLoader();
  }

  nothingFound() {
    this.hideLoader();
    const container = document.createElement("div");
    const heading = document.createElement("h4");
    container.classList.add("search-results");
    heading.innerText = "Brak wyników dla wpisanej frazy";
    container.appendChild(heading);
    this.resultsContainer.appendChild(container);
  }

  showLoader() {
    this.loader.classList.add("show");
  }

  hideLoader() {
    this.loader.classList.remove("show");
  }
}
