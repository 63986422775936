export default class faqContact {
  constructor() {
    this.questions = [
      ...document.querySelectorAll(".faqs-container .question")
    ];
    this.answers = [...document.querySelectorAll(".faqs-container .answer")];

    this.addEventListeners();
  }

  addEventListeners() {
    this.questions.forEach(question => {
      question.addEventListener("click", e => {
        e.preventDefault();
        const indx = this.questions.indexOf(e.target);
        this.answers[indx].classList.toggle("show");
      });
    });
  }
}
