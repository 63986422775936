// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".HomepageSlider__slides"),
  team: $(".contactPeople .inner"),
  partners: $(".ourPartners .inner")
};

sliders.team.slick({
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  appendArrows: $(".contactPeople__slider nav"),
  infinite: false,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.partners.slick({
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  appendArrows: $(".ourPartners__slider nav"),
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.home.slick({
  appendDots: $(".HomepageSlider__dots"),
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  fade: true
});
