export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

export const handleMobileCategoryList = btn => {
  const parentEl = btn.parentNode;
  const list = parentEl.querySelector(".mobile-list-container");

  btn.addEventListener("click", e => {
    btn.classList.toggle("open");
    list.classList.toggle("open");
  });
};

export const handlePopupMovie = () => {
  const popup = document.querySelector(".moviePopup");
  const openBtn = document.querySelector(".play-movie");
  const closeBtn = document.querySelector(".moviePopup__close-button");
  const video = popup.querySelector("video");

  openBtn.addEventListener("click", e => {
    e.preventDefault();
    popup.classList.add("open");
    video.play();
  });

  closeBtn.addEventListener("click", e => {
    e.preventDefault();
    popup.classList.remove("open");
    video.pause();
  });
};

export const getSearchLength = data => {
  const objectValues = Object.values(data);
  const dataLength = [].concat(...objectValues).length;
  return dataLength;
};
