import { getCoords } from "./helper";
export default class SiteHeader {
  constructor(
    defaultSettings = {
      setBodyPaddingTop: true,
      stickyHeader: true
    }
  ) {
    this.defaultSettings = defaultSettings;
    this.siteHeader = document.getElementById("masthead");
    this.init();
  }

  init() {
    ["home", "blog", "post-type-archive-product", "tax-product_cat", "single-product", "woocommerce-cart", "woocommerce-checkout", "page-template-about", "page-template-offer", "page-template-contact"].forEach(el => {
      if (document.body.classList.contains(el)) {
        this.defaultSettings.setBodyPaddingTop = false;
      }
    });

    this.defaultSettings.setBodyPaddingTop === false ||
      this.setBodyPaddingTop();
    this.defaultSettings.stickyHeader === false || this.stickyHeader();
  }

  setBodyPaddingTop() {
    let $body = document.querySelector("body"),
      siteHeaderHeight = this.siteHeader.clientHeight;

    $body.style.paddingTop = `${siteHeaderHeight}px`;
  }

  stickyHeader() {
    window.addEventListener("scroll", () => {
      let headerOffsetTop = getCoords(this.siteHeader).top;

      if (headerOffsetTop > 0) this.siteHeader.classList.add("sticky");
      else this.siteHeader.classList.remove("sticky");
    });
  }
}
