import $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader";

class PartnersMap {
  constructor() {
    this.loader = new Loader({
      apiKey: "AIzaSyC6oIdxrqDexJ5ekr3aQEQJHBDwch_CgtY"
    });
    this.map = null;
    this.partners = document.querySelectorAll(".ourPartners__slide");
    this.coords = [];
    this.selectedMarker = null;
    this.defaultIcon =
      "https://van-care.pl/wp-content/themes/vancare/dist/images/icons/pin-inactive.svg";
    this.chosenIcon =
      "https://van-care.pl/wp-content/themes/vancare/dist/images/icons/pin-active.svg";

    this.loadMap();
  }

  setMarkers() {
    this.partners.forEach(el => {
      const myLatLng = { lat: +el.dataset.lat, lng: +el.dataset.lng };
      const map = this.map;
      const image = this.defaultIcon;
      const marker = new google.maps.Marker({
        position: myLatLng,
        map,
        title: el.dataset.name,
        icon: image
      });

      marker.addListener("click", () => {
        if (this.selectedMarker) {
          this.selectedMarker.setIcon(this.defaultIcon);
        }
        marker.setIcon(this.chosenIcon);
        this.selectedMarker = marker;

        $(".ourPartners__slider .inner").slick("slickGoTo", el.dataset.index);
      });
    });
  }

  loadMap() {
    this.loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById("partnersMap"), {
        center: { lat: 52.0914, lng: 19.2206 },
        zoom: 6,
        disableDefaultUI: true
      });
      this.setMarkers();
    });
  }
}

export default PartnersMap;
