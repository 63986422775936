import $ from "jquery";
import { isMobile, handleMobileCategoryList, handlePopupMovie } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import SearchForm from "./searchform";
import gridListSwitch from "./woocommerce/grid-list-switch";
import quantityButtonsHandler from "./woocommerce/quantity-buttons";
import addToCart from "./woocommerce/add-to-cart";
import faqContact from "./faq";
import PartnersMap from "./maps";
import AOS, { init } from "aos";
import "aos/dist/aos.css";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  AOS.init({
    animatedClassName: "animated"
  });

  const shopMenuContainer = document.querySelector(".shop-menu-container");
  if (shopMenuContainer) {
    document.body.addEventListener("show-cart-preview", () => {
      shopMenuContainer.classList.add("temp-open");
      setTimeout(() => {
        shopMenuContainer.classList.remove("temp-open");
      }, 3500);
    });
  }

  const searchFormContainer = document.querySelector(".search-form-container");
  if (searchFormContainer) {
    new SearchForm(searchFormContainer);
  }

  if (document.querySelector(".ajax-add-to-cart")) {
    addToCart();
  }

  if (document.querySelector(".qty-wrapper")) {
    quantityButtonsHandler();
  }

  if (document.querySelector(".grid-list-selection")) {
    gridListSwitch();
  }

  if (document.querySelector(".faqs-container")) {
    new faqContact();
  }

  const mobileCategoriesListBtn = document.querySelector(
    ".show-mobile-category-list"
  );
  if (mobileCategoriesListBtn) {
    handleMobileCategoryList(mobileCategoriesListBtn);
  }

  if (document.querySelector(".moviePopup")) {
    handlePopupMovie();
  }

  if (document.getElementById("partnersMap")) {
    new PartnersMap();
  }

  $(document).on("forminator:form:submit:success", () => {
    gtag("event", "wyslany", { event_category: "formularz" });
  });

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
