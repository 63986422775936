const quantityButtonsHandler = () => {
  const increaseBtn = document.querySelector(".qty-wrapper .increase-qty");
  const decreaseBtn = document.querySelector(".qty-wrapper .decrease-qty");
  const input = document.querySelector(".qty-wrapper .qty");
  const inStock = parseInt(
    document.querySelector(".qty-wrapper").getAttribute("data-stock")
  );
  let value = null;

  increaseBtn.addEventListener("click", e => {
    e.preventDefault();
    value = input.value;
    if (inStock > 0) {
      if (value < inStock) {
        value++;
        input.value = value;
      }
    } else {
      value++;
      input.value = value;
    }
  });

  decreaseBtn.addEventListener("click", e => {
    e.preventDefault();
    value = input.value;
    if (value > 1) {
      value--;
      input.value = value;
    }
  });
};

export default quantityButtonsHandler;
